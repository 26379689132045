<template>
  <div class="box">
    <!-- <van-button type="default" @click="handleClickFilter"
      ><span>{{ tradingType }}</span
      ><span class="triangle-down"></span>
    </van-button> -->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item @change="changeItem" v-model="dropdownValue" :options="typeList" />
    </van-dropdown-menu>
    <!-- 列表 -->
    <van-pull-refresh
      v-show="!billIsNull"
      v-model="refreshing"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell
          v-for="(item, index) in list"
          :key="index"
          @click="checkOrder(item)"
        >
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div class="left-box">
              <div class="tradingIcon">
                <img :src="imgUrls[item.bill_type]" />
              </div>
              <div style="display: flex; flex-direction: column">
                <span class="tradingName">{{
                  item.bill_type | titleContent(typeList)
                }}</span
                ><span class="tradingTime">{{
                  item.created_at | tradeTime
                }}</span>
              </div>
            </div>
            <div :class="item.money | moneyColor">{{ item.money }}</div>
          </template>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <van-empty
      v-show="billIsNull"
      class="custom-image"
      :image="require('../../assets/images/icon-null.png')"
      description="暂无账单信息"
    />
    <!-- 弹窗 -->
    <!-- <van-action-sheet
      :lazy-render="false"
      get-container="#app" 
      v-model="show"
      title="交易类型"
      :lock-scroll="true"
    >
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell
            v-for="item in typeList"
            :key="item.value"
            :title="item.text"
            clickable
            @click="handleRadio(item.value, item.text)"
          >
            <template #right-icon>
              <van-radio v-show="item.value == radio" :name="item.value" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-action-sheet> -->
  </div>
</template>

<script>
import { GetAllBillList } from "@/api/bill";

import qs from "qs";
export default {
  name: "Bill",
  data() {
    return {
      show: false,
      radio: 0,
      imgUrls: {
        0: require("../../assets/images/icon-0.jpg"),
        1: require("../../assets/images/icon-1.png"),
        2: require("../../assets/images/icon-2.png"),
        3: require("../../assets/images/icon-3.png"),
        4: require("../../assets/images/icon-4.png"),
        5: require("../../assets/images/icon-5.png"),
        6: require("../../assets/images/payment.png"),
        7: require("../../assets/images/payment.png"),
        8: require("../../assets/images/icon-1.png"),
      },
      // 账单类型，0:全部，1:订单消费，2:扫码付款，3:退款，4:慰问金平台发放，
      // 5：餐卡结余，6：温度卡充值，7：补助发放，8：线下消费
      typeList: [
        { text: "全部交易类型", value: 0 },
        { text: "订单消费", value: 1 },
        { text: "扫码付款", value: 2 },
        { text: "退款", value: 3 },
        { text: "慰问金发放", value: 4 },
        { text: "餐卡结余", value: 5 },
        { text: "温度卡充值", value: 6 },
        { text: "补助发放", value: 7 },
        { text: "线下消费", value: 8 },
      ],
      dropdownValue: 0,
      // tradingType: "全部交易类型",
      refreshing: "",
      loading: false,
      finished: false,
      list: [],
      page: 1,
      size: 10,
      //账单有无数据
      billIsNull: false,
      token: "",
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    // console.log("我是token" + this.$route.query.token);
    // this.token = 'd20f6c6baf4d0b64dc058bc9225b4c1f'
  },
  filters: {
    titleContent(val,list) {
      let result = list.filter((item) => {
        return val == item.value;
      });
      return result[0].text;
    },
    tradeTime(val) {
      let timeEnd = val.split(" ")[1];
      let dataTime = val.split(" ")[0].split("-");
      let month = dataTime[1];
      let day = dataTime[2];
      // console.log(dataTime[1].split('')[0])
      if (month.length > 1) {
        if (month.split("")[0] == 0) {
          month = month.split("")[1];
        }
      }
      if (day.length > 1) {
        if (day.split("")[0] == 0) {
          day = day.split("")[1];
        }
      }
      return month + "月" + day + "日" + " " + timeEnd;
    },
    moneyColor(val) {
      let isColor = val.split("");
      // console.log(isColor)
      if (isColor[0] == "-") {
        return "money";
      } else if (isColor[0] == "+") {
        return "up";
      }
    },
  },
  methods: {
    // goBack() {
    // },
    handleClickFilter() {
      // console.log(this.$toast)
      this.show = true;
    },
    changeItem(val){
      // console.log(val)
      this.page = 1;
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.onLoad();

    },
    handleRadio(val, name) {
      console.log(val)
      this.radio = val;
      this.show = false;
      this.tradingType = name;
      this.page = 1;
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onRefresh() {
      this.page = 1;
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    //加载数据
    onLoad() {
      // let url = location.search
      // let token = location.search.split('=')[1]
      // console.log(token.split('=')[1])
      if (this.refreshing) {
        this.list = [];
        this.page = 1;
        this.refreshing = false;
      }
      let params = {
        // db72ab22e7af3c28fb1caaa7b9bc3f2d
        token: this.token,
        // token: '6f989f62c3c924e1a85f66343617afc4',
        bill_type: this.dropdownValue,
        page: this.page++,
        size: this.size,
      };
      // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
      GetAllBillList(qs.stringify(params))
        .then((res) => {
          let { data, code, message } = res;
          console.log("账单接口请求成功 结果如下");
          console.log(data);
          if (code != 0) return this.$toast(message || "网络请求错误");
          if (data.list && data.list.length != 0) {
            data.list.forEach((item, index) => {
              this.list.push(data.list[index]);
            });
            this.loading = false;
            // this.list = data
            this.billIsNull = false;
            if (this.list.length >= data.count) {
              this.finished = true;
            }
          } else {
            this.billIsNull = true;
            this.finished = true;
          }
        })
        .catch((err) => {
          this.billIsNull = true;
          this.finished = true;
          return this.$toast("网络请求错误");
        });
    },
    checkOrder(val) {
      if (val.bill_type != 4 && val.bill_type != 5) {
        var u = navigator.userAgent;
        var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
          // android
          window.AndroidWebView.showInfoFromJs(val);
        }
        if (isiOS) {
          // IOS
          window.webkit.messageHandlers.showMessage.postMessage(val);
        }
      } else {
        //文案
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// #box {

//   background-color: #f7f7f7;
// }

.van-button {
  border: 0;
  width: 100%;
  font-size: 14px;
}

.van-button__text {
  display: flex;
  align-items: center;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid#C7C7C7;
}

.van-empty ::v-deep .van-empty__image {
  width: 175px;
  height: 138px;
}
.transaction-class {
  height: 50px;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
}
/* 列表 */
.van-pull-refresh {
  border-top: 8px solid #f7f7f7;
}
.van-pull-refresh .van-cell {
  height: 76px;
}
.van-pull-refresh .van-cell .van-cell__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-box {
  display: flex;
  align-items: center;
}
.tradingName {
  font-size: 14px;
  color: #40404e;
  // font-weight: 700;
}
.tradingTime {
  font-size: 12px;
  color: #bfbfc9;
}
.money {
  font-size: 16px;
  color: #40404e;
  font-weight: 700;
}
.up {
  font-size: 16px;
  color: #ff8420;
  font-weight: 700;
}
.tradingIcon {
  width: 44px;
  height: 44px;
  margin-right: 8px;
}
.tradingIcon img {
  width: 100%;
  height: 100%;
}
// .van-action-sheet {
//   height: 300Px!important;
// }
::v-deep.van-action-sheet__content {
  // height: 100%;
  // padding-bottom: 20Px;
  overflow: auto;
}
</style>